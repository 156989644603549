.card {
  position: relative;
  width: 100%;

  > iframe {
    border: none;
    width: 100%;
    height: calc(100vh - 160px);
  }
}

@border-radius-base: 5px;