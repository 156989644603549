.organization-growth-table td.ant-table-cell {
  text-align: center;
}
.organization-growth-table .ant-table-thead > tr > th {
  text-align: center;
}
.organization-growth-table .ant-table-thead > tr > th:nth-child(2) {
  text-align: left;
}
.organization-growth-table td.ant-table-cell:nth-child(2) {
  text-align: left;
}
.organization-growth-table .ant-form-item {
  margin: 0;
}

@border-radius-base: 5px;