.day-cell {
  &__row {
    display: flex;
    gap: 1px;
    justify-content: space-evenly;

    &--present {
      min-width: 50px;
      text-align: center;
      background-color: #005f6815;
      font-size: 0.8rem;
      border-radius: 5px;
    }

    &--absent {
      min-width: 50px;
      text-align: center;
      background-color: rgba(255, 233, 233, 0.699);
      font-size: 0.8rem;
      border-radius: 5px;
    }
  }
}

.attendance-monitoring {
  &__header-row {
    display: flex;
    gap: 16px;

    .ant-form-item {
      flex: 1;
    }
  }
}

.attendance-monitoring-table td.ant-table-cell {
  text-align: center;
  padding: 4px;
}

.attendance-monitoring-table .ant-table-thead > tr > th {
  text-align: center;
}
.attendance-monitoring-table .ant-table-thead > tr > th:nth-child(1) {
  text-align: left;
}
.attendance-monitoring-table td.ant-table-cell {
  text-align: center;
}
.attendance-monitoring-table td.ant-table-cell:nth-child(1) {
  text-align: left;
}

@border-radius-base: 5px;