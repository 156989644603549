.version {
  padding: 24px !important;
  bottom: 0;
  left: 16px;
  position: absolute;
  color: white;
  p {
    margin: 0;
  }
}
.ant-layout-sider-children {
  padding-bottom: 100px;
}

@border-radius-base: 5px;