.steps-content {
  min-height: 200px;
  position: relative;
}

.steps-action {
  margin-top: 24px;
}

.citizenship {
  display: flex;
  align-items: center;
  flex-direction: column;

  &__btn {
    display: flex;
    gap: 8px;
    margin-top: 20px;
    flex-grow: 0;
    flex-direction: column;
    align-items: center;

    > button {
      width: min-content;
    }
  }
}

.pupil-search-native {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__form {
    min-width: 300px;
  }
}

.pupil-search-foreign {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__form {
    min-width: 300px;
  }
}

.pupil-info {
  &__avatar {
    margin: 12px 0;
  }
}

.pupil-info-parents {
  margin-top: 12px;
}

@border-radius-base: 5px;