.roles-form {
  padding: 25px;
}

.form__header-buttons {
  position: absolute;
  top: 12px;
  right: 15px;
  display: flex;
  align-items: center;
}

.role__name {
  margin: 20px 0;
  font-size: 17px;
  font-weight: 400;
}

.roles-form__wrapper {
  display: flex;
  margin: 20px 0;
  padding-bottom: 20px;
  width: 100%;
}

.roles-form__item {
  width: 22%;
}

.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 12px 17px;
  border-right: 1px solid #f0f0f0;
}

.roles_wrapper .ant-col {
  min-width: 100%;
}

@border-radius-base: 5px;