.breadcrumbs {
  padding: 16px 24px;
  display: flex;
  align-items: center;

  &__item {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;

    &--title {
      margin-right: 24px;
      font-size: 1rem;
      :hover {
        text-decoration: underline;
      }
    }

    &--organization {
      margin: 0;
      margin-right: 24px;
      color: #5092aa;
      font-size: 1.1rem;
      font-weight: 600;
    }
  }
}

@border-radius-base: 5px;