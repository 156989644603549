.menu-seasons {
  &__header {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
  }

  &__info {
    padding: 24px;

    &--name {
      font-size: 1.5rem;
      text-align: center;
    }

    &--range {
      display: flex;
      justify-content: center;
      gap: 120px;

      .point {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-width: 120px;
        min-height: 120px;
        border-radius: 50%;
        color: white;
        background: #5092aa;
        text-align: center;
      }
    }

    &--footer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .menus {
        &__title {
          font-size: 1.2rem;
        }

        &__items {
          display: flex;
          gap: 16px;
        }
      }
    }
  }

  &__add-menu {
    .title {
      font-weight: 600;
      margin: 0;
      margin-bottom: 8px;
    }

    .add-btn {
      margin: 0;
    }
  }

  &__current {
    margin-top: 16px;
    .title {
      font-weight: 600;
      margin: 0;
      margin-bottom: 8px;
    }

    .item {
      display: flex;
      justify-content: space-between;

      &__title {
        color: var(--primary-color);
        padding: 0;
        margin: 0 8px 0 0;
      }

      &__minus {
        border: 1px solid gray;
        display: flex;
        justify-content: center;
        background: whitesmoke;
        border-radius: 5px;
        width: 25px;
        opacity: 0.8;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

@border-radius-base: 5px;