.navbar {
  display: flex;
  height: 100%;
  width: 100% !important;
  justify-content: space-between;
  background-color: var(--menu-color);

  &__items {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-right: 24px;

    .item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &__link {
        display: flex;
        padding: 0;
        margin: 0;
        align-items: center;
        color: white;
        height: 20px;
        transition: all 0.1s linear;
      }

      &__icon {
        display: flex;
        color: white;
        transition: all 0.1s linear;
        transform: scale(1);
      }
    }

    .item:hover .item__link a {
      opacity: 0;
    }

    .item:hover .item__link {
      opacity: 0;
    }

    .item:hover .item__icon {
      transform: scale(2) translateY(-6px);
    }
  }
}

.navbar .ant-menu-title-content > div,
.navbar .ant-menu-title-content a {
  color: white;
  font-size: 1rem;
}

.navbar .ant-select-selection-item {
  color: var(--menu-color);
}

.ant-menu-item:hover {
  background: none;
}

.ant-layout-header {
  padding: 0;
}

.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
  display: flex;
  justify-content: flex-end;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: transparent;
}

.ant-layout-header .ant-menu {
  display: flex;
  justify-content: flex-end;
}

@border-radius-base: 5px;