.indicators-header {
  padding: 24px;
  margin-bottom: 20px;
}

.indicators__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-left: 18px;
  margin: 10px 0;
}

.indicators__item--bold-text {
  font-weight: 500;
}

.ant-statistic-title {
  color: #333;
  font-weight: 500;
}

.indicators__list {
  list-style: none;
  padding: 0;
}

@border-radius-base: 5px;