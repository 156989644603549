.manager-page {
  &__header {
    width: 100%;
    padding: 24px;
    margin-bottom: 60px;
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 0 10px 0.1px #dfdfdf;
    transition: all 0.3s;

    img {
      border-radius: 50%;
      border: 1px solid rgb(235, 226, 226);
      object-fit: cover;
      object-position: center;
    }

    &--right {
      margin-left: 24px;
    }

    &--doo {
      font-size: 1.5rem;
      font-weight: 600;
      margin: 0;
    }

    &--name {
      margin: 0;
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  &__nav {
    img {
      width: max(100px, 7vw);
      margin: 0;
      padding: 0;
      cursor: pointer;
      opacity: 0.9;
      transition: 0.2s;
      border-radius: 50%;
      box-shadow: 5px 5px 8px 2px rgb(223, 223, 223);

      &:hover {
        opacity: 1;
        transform: scale(0.97);
        box-shadow: none;
      }
    }

    p {
      font-weight: 600;
      text-align: center;
    }

    &--top {
      display: flex;
      justify-content: space-around;
      margin: auto auto 48px auto;
    }

    &--bottom {
      display: flex;
      margin: auto 60px auto 60px;
      justify-content: space-evenly;
    }
  }
}

@border-radius-base: 5px;