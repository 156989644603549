.queuePercentage {
  position: relative;
  padding-top: 5px;
}
.exception-button {
  position: absolute;
  top: -2px;
  right: 0;
  font-size: 11px;
}

@border-radius-base: 5px;