.menu-calendar {
  &__header {
    display: flex;
    gap: 1px;

    &--btn {
      text-align: center;
      flex: 1;
      padding: 8px 16px;
      font-size: 1rem;
      border-radius: 4px 4px 0 0;
      color: white;
      background: var(--primary-color);
      cursor: pointer;
      opacity: 0.9;
      transition: 0.3s;
      &:hover {
        opacity: 1;
        box-shadow: none;
      }
    }

    > .selected {
      opacity: 1;
      background: #40839b;
    }
  }

  &__content {
  }
}

@border-radius-base: 5px;