.reference-book-page {
  background-color: #fff;
  border-radius: 8px;
  padding: 25px;
  margin: 0 0 20px 20px;
}
.reference-book-page__header {
  border-bottom: 1px solid #ebedf3;
}
.reference-book-page__title {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 15px;
}
.reference-book-page__table {
  border: 1px solid #ebedf3;
  width: 100%;
  height: 75px;
  text-align: left;
  margin-top: 30px;
  border-top: 0;
}

.reference-book-page__table tr {
  border-top: 1px solid #ebedf3;
  padding: 10px;
}
.reference-book-page__table tr:hover {
  background-color: #e4e6ef;
}
.reference__link {
  color: #333;
}
.reference__link:hover {
  color: var(--primary-color);
}

.reference-book-page__table th {
  padding: 20px 10px;
  vertical-align: top;
  border-top: 1px solid #ddd;
  line-height: 1.4;
  border-right: 1px solid #ebedf3;
}
.reference-book-page__table td {
  border-right: 1px solid #ebedf3;
  line-height: 1.4;
  padding: 20px 10px;
}
.reference-book-page__table a {
  color: #333;
}
.reference-book-page__table a:hover {
  color: dodgerblue;
}
.reference-book-page__table td:first-child {
  width: 5%;
}

@media (max-width: 991px) {
}

@border-radius-base: 5px;