.logo {
  display: block;
  padding-left: 24px;
  position: absolute;
  left: 0;
  background-color: var(--menu-color);
}
.logo__img {
  width: 150px;
}

@border-radius-base: 5px;