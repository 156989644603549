.employee-attendance-table .ant-table-thead > tr > th {
  text-align: center;
}
.employee-attendance-table td.ant-table-cell {
  text-align: center;
}
.employee-attendance-table td.ant-table-cell:nth-child(2) {
  text-align: left;
}
.employee-attendance-table {
  p {
    margin: 0 !important;
  }
}

.pupil-attendance-table .ant-table-thead > tr > th {
  text-align: center;
}
.pupil-attendance-table td.ant-table-cell {
  text-align: center;
}
.pupil-attendance-table td.ant-table-cell:nth-child(2) {
  text-align: left;
}

.nested-table-attendance .ant-table-thead > tr > th {
  text-align: center;
}
.nested-table-attendance td.ant-table-cell {
  text-align: center !important;
}
.nested-table-attendance td.ant-table-cell:nth-child(1) {
  text-align: left !important;
}

.attendance__save-btn {
  margin-left: 10px;
}

.nested-table-attendance {
  margin-top: 12px;
  transform: translateX(-24px);

  p {
    padding: 0;
    margin: 0 !important;
  }
}

@border-radius-base: 5px;