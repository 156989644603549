.ant-layout {
  overflow: hidden;
}

.meals {
  .meal-row {
    cursor: pointer;
  }

  &__popup {
    position: absolute !important;
    padding: 16px;
    border-radius: 10px;
    left: var(--popup-x);
    top: var(--popup-y);
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid gray;
    cursor: pointer;

    &--title {
      font-size: 1rem;
      font-weight: 600;
    }

    &--product {
      display: flex;
      flex-direction: row;
    }

    p {
      margin: 0;
      padding: 0;
    }
  }

  .visible {
    visibility: visible;
  }

  .hidden {
    visibility: hidden;
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
  }
}

// .meals .ant-tag .ant-tag a, .ant-tag {
//     color: white;
//     margin: 4px;
//     padding: 4px 8px;
//     border-radius: 16px;
//     font-weight: 600;
//     background: var(--secondary-color);

//     svg {
//         color: white;
//     }
// }

.meals .ant-table-thead > tr > th {
  text-align: center;
}
.meals .ant-table-thead > tr > th:nth-child(3) {
  text-align: left;
}
.meals td.ant-table-cell {
  text-align: center;
}
.meals td.ant-table-cell:nth-child(3) {
  text-align: left;
}

@border-radius-base: 5px;