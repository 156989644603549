.statistics {
  height: 800px;
  background-size: auto;
  background-image: url('../../assets/images/graph.png');
  background-repeat: no-repeat;
  background-position: center;
}

.statistics2 {
  height: 800px;
  background-size: auto;
  background-image: url('../../assets/images/graph2.png');
  background-repeat: no-repeat;
  background-position: center;
}

.card {
  position: relative;
  width: 100%;

  > iframe {
    border: none;
    width: 100%;
    height: calc(100vh - 160px);
  }
}

@border-radius-base: 5px;