.auth {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__bg {
    background-image: url('../../assets/images/auth-photo.jpg');
    background-position: center;
    background-size: cover;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &__left {
    flex: 0.6;
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    margin: auto;

    &--mask {
      background-color: aliceblue;
      height: 100%;
      width: 100%;
      opacity: 0.6;
      position: absolute;
    }

    .language {
      position: absolute;
      top: 30px;
      right: 24px;
      z-index: 100;
      font-weight: 600;

      span {
        color: #2997ff;
        font-size: 1rem;
      }
    }

    &--form {
      width: min(60%, 450px);
      height: min(70%, 500px);
      z-index: 100;
      border-radius: 15px;
      margin: auto;
    }

    & .ant-form {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: rgba(245, 245, 245, 0.9);
      padding: 24px;

      > h2 {
        margin-bottom: 24px;
        font-size: 1.6rem;
        text-align: center;
      }

      .recaptcha {
        align-self: center;
      }

      .login-egov {
        font-size: 1.2rem;
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    flex: 0.4;
    position: relative;

    img {
      position: relative;
      width: 50%;
      z-index: 100;
    }

    .description {
      padding: 24px;
      color: white;
      text-align: center;
      z-index: 100;
    }

    .footer {
      color: white;
      position: absolute;
      text-align: center;
      width: 100%;
      bottom: 10px;
      z-index: 100;
    }

    &--mask {
      background-image: url('../../assets/images/auth-bg.jpg');
      background-size: cover;
      height: 100%;
      width: 100%;
      opacity: 0.9;
      position: absolute;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .auth {
    &__left {
      flex: 1;
      &--form {
        width: min(70%, 450px);
      }

      & .ant-form {
        > h2 {
          font-size: 1.5rem;
        }

        .login-egov {
          font-size: 1.1rem;
        }
      }

      &--mask {
        background-color: aliceblue;
        height: 100%;
        width: 100%;
        opacity: 0.6;
        position: absolute;
      }
    }

    &__right {
      display: none;
    }
  }
}

@media only screen and (max-width: 768px) {
  .auth {
    &__left {
      &--form {
        width: 100%;
        height: 100%;
      }

      & .ant-form {
        > h2 {
          font-size: 1.5rem;
        }
      }

      &--mask {
        background-color: aliceblue;
        height: 100%;
        width: 100%;
        opacity: 0.6;
        position: absolute;
      }
    }

    &__right {
      display: none;
    }
  }
}

@media only screen and (max-width: 600px) {
  .auth {
    &__left {
      &--form {
        width: 100%;
        border-radius: 0;
      }

      & .ant-form {
        > h2 {
          font-size: 1.3rem;
        }

        .login-egov {
          font-size: 1rem;
        }
      }

      &--mask {
        background-color: aliceblue;
        height: 100%;
        width: 100%;
        opacity: 0.6;
        position: absolute;
      }
    }

    &__right {
      display: none;
    }
  }
}

@border-radius-base: 5px;