.warehouse-invoice-add-form {
  &__add {
    &--label {
      margin-bottom: 8px;
    }

    &--input {
      display: flex;
      gap: 24px;

      .ant-form-item {
        width: 100%;
        margin-bottom: 8px;
      }
    }
  }
}

.warehouse-invoice-products {
  &__title {
    margin-top: 24px;
  }

  &__item {
    display: flex;
    justify-content: space-between;

    &--title {
      color: var(--primary-color);
      padding: 0;
      margin: 0 8px 0 0;
    }

    &--minus {
      border: 1px solid gray;
      display: flex;
      justify-content: center;
      background: whitesmoke;
      border-radius: 5px;
      width: 25px;
      opacity: 0.8;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        opacity: 1;
      }
    }
  }
}

@border-radius-base: 5px;