.tutorials {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  justify-items: center;
  align-items: center;
  grid-gap: 50px;

  .tutorial {
    width: 100%;
    padding: 24px;

    iframe {
      width: 100%;
      aspect-ratio: 2 / 1;
    }

    &__title {
      font-size: 1.3rem;
    }

    &__description {
    }

    &__video {
      aspect-ratio: 2 / 1;
    }
  }
}

@border-radius-base: 5px;