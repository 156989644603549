.menu-calendar-by-pupil {
  &__header {
    background: white;
    padding: 24px;
    &--form {
      display: flex;

      .filters {
        display: flex;
        flex: 1;

        .ant-form-item {
          margin: 0;
          margin-left: 24px;
          flex: 1;
        }
      }
    }

    &--btn {
      margin-top: 24px;
    }
  }

  &__body {
    display: flex;
    margin-top: 16px;
    gap: 16px;

    &--left {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .workDate {
        font-weight: 600;
        padding: 8px 24px;
        width: min-content;
        font-size: 1.2rem;
        border-radius: 0 4px 4px 0;
        cursor: pointer;
        transition: all 0.1s ease-in-out;
        box-shadow: 0 0 10px 0.1px #dfdfdf;
      }

      .selected {
        color: white;
        background: var(--primary-color);
      }

      .none-selected {
        background: white;
      }
    }

    &--right {
      width: 100%;
      background-color: white;
      padding: 16px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0.1px #dfdfdf;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__count {
          font-size: 1.2rem;
        }
        &__menu-name {
          font-size: 1.2rem;
        }
      }

      .item {
        padding-bottom: 8px;

        &__meal-type {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 24px;
          font-size: 1.2rem;
          width: 100%;
          border-radius: 4px;
          background-color: #5092aa;
          color: white;
          cursor: pointer;

          p {
            margin: 0;
            padding: 0;
          }

          svg {
            margin: 0;
            padding: 0;
          }

          .arrow {
            transition: all 0.2s linear;
          }

          .arrow-expanded {
            transform: rotate(-180deg);
          }

          .arrow-collapsed {
            transform: rotate(0deg);
          }
        }

        &__meal-body {
          transition: opacity 0.2s ease-in-out;
          overflow: hidden;
          align-items: center;
        }

        .meal-collapsed {
          opacity: 0;
          max-height: 0;
        }

        .meal-expanded {
          opacity: 1;
          max-height: 100%;
        }

        &__meal-top {
          margin-left: 24px;

          .title {
            font-size: 1.1rem;
            font-weight: 600;
            margin: 16px 0 0 0;
          }

          .amount {
            font-weight: 600;
          }
        }

        &__meal-bottom {
          display: flex;
          width: 100%;
          padding: 0 24px 8px 24px;

          img {
            border: 1px solid rgba(128, 128, 128, 0.4);
            min-width: 200px;
            flex: 1;
          }

          .products {
            width: 100%;
            margin-left: 24px;

            &__product {
              display: flex;
              justify-content: space-between;
              margin: 0;
              padding: 0;
              border-bottom: 1px solid var(--divider);
            }

            p {
              margin: 0;
            }
          }
        }
      }

      .total {
        padding-bottom: 8px;

        &__label {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 24px;
          font-size: 1.2rem;
          width: 100%;
          border-radius: 4px;
          background-color: #5092aa;
          color: white;
          cursor: pointer;

          p {
            margin: 0;
            padding: 0;
          }

          svg {
            margin: 0;
            padding: 0;
          }

          .arrow {
            transition: all 0.2s linear;
          }

          .arrow-expanded {
            transform: rotate(-180deg);
          }

          .arrow-collapsed {
            transform: rotate(0deg);
          }
        }

        &__body {
          transition: opacity 0.2s ease-in-out;
          overflow: hidden;
        }

        .total-collapsed {
          opacity: 0;
          max-height: 0;
        }

        .total-expanded {
          opacity: 1;
          max-height: 100%;
        }

        &__product {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 24px 0 24px;
          border-bottom: 1px solid var(--divider);

          p {
            margin: 0;
          }

          &--title {
            font-weight: 600;
          }

          &--amount {
            font-weight: 600;
          }
        }
      }
    }
  }
}

@border-radius-base: 5px;