.menu-content {
  &__add {
    .title {
      font-weight: 600;
      margin: 0;
      margin-bottom: 8px;
    }

    .form {
      display: flex;
      justify-content: space-between;
    }

    .add-btn {
      margin: 0;
    }
  }

  &__current {
    margin-top: 16px;
    .title {
      font-weight: 600;
      margin: 0;
      margin-bottom: 8px;
    }

    .item {
      display: flex;
      justify-content: space-between;

      &__title {
        color: var(--primary-color);
        padding: 0;
        margin: 0 8px 0 0;
      }

      &__minus {
        display: flex;
        justify-content: center;
        background: var(--primary-color);
        color: white;
        border-radius: 5px;
        width: 25px;
        opacity: 0.8;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
          opacity: 1;
        }
      }
    }

    .meals {
      &__form {
        display: flex;
        justify-content: space-between;
      }

      &__item {
        display: flex;
        justify-content: space-between;

        &--minus {
          display: flex;
          justify-content: center;
          border: 1px solid gray;
          background: whitesmoke;
          border-radius: 5px;
          width: 25px;
          opacity: 0.8;
          cursor: pointer;
          transition: 0.2s;
        }
      }
    }
  }
}

@border-radius-base: 5px;