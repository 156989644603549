.reports {
  &__buttons {
    padding: 25px;

    &--item {
      font-size: 1rem;
      color: dodgerblue;
      text-decoration: underline;
      cursor: pointer;
      margin-bottom: 24px;
    }
  }
}

@border-radius-base: 5px;