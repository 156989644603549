.employee-add-form {
  padding: 25px;
  min-width: 50%;
}

.employee-assign_wrapper {
  display: flex;
  justify-content: space-between;
}

.employee-assign_wrapper .ant-descriptions-view {
  width: 100%;
}

.ant-descriptions-bordered .ant-descriptions-view {
  border-radius: 0;
  border-right: 0;
}

.ant-table-thead > tr > th {
  border-top: 1px solid #f0f0f0;
}

.search-item {
  position: relative;
}

.search-button {
  position: absolute;
  right: 10px;
  top: 0;
  z-index: 50;
}

.margin-bottom {
  margin-bottom: 16px;
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
}

@border-radius-base: 5px;