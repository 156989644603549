.scheduler {
  .ant-tabs-nav-wrap {
    width: 100%;
    display: inline !important;

    .ant-tabs-tab {
      display: flex;
      justify-content: center;
    }
  }

  .ant-tabs-nav-wrap .ant-tabs-tab {
    flex: 1;
  }

  .info {
    display: flex;
    padding: 24px;

    &__left {
      flex: 0.6;

      &--span-active {
        color: green;
      }
      &--span-inactive {
        color: red;
      }
    }
    &__right {
      flex: 0.4;
      justify-content: center;
      text-align: center;

      > h1 {
        font-size: 5rem;
      }
    }
  }

  .warning {
    text-align: center;
    padding: 24px;

    > h2 {
      color: rgba(255, 0, 0, 0.644);
    }
  }

  .settings {
    padding: 24px;

    .ant-checkbox-wrapper {
      margin: 0;
      margin-bottom: 20px;
    }

    &__options {
      display: flex;
      flex-direction: column;
    }

    &__btn {
      .ant-btn {
        margin-right: 16px !important;
      }
    }
  }
}

@border-radius-base: 5px;