.products .ant-table-thead > tr > th {
  text-align: center;
}
.products .ant-table-thead > tr > th:nth-child(2) {
  text-align: left;
}
.products td.ant-table-cell {
  text-align: center;
}
.products td.ant-table-cell:nth-child(2) {
  text-align: left;
}

.products {
  &__header {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
  }
}

@border-radius-base: 5px;