.queue-list {
  display: flex;
  gap: 60px;
  align-items: center;
  width: 100%;

  &__left {
    &--summary {
      font-size: 1.1rem;
      font-weight: 600;
    }

    &--total {
      font-size: 1rem;
    }

    &--benefit {
      font-size: 1rem;
    }

    &--no-benefit {
      font-size: 1rem;
    }

    &--ages {
      display: flex;
      gap: 8px;

      .item {
        background-color: aliceblue;
        padding: 4px;
        border-radius: 10px;
        font-weight: 600;
      }
    }

    &--error {
      color: red;
    }
  }

  &__right {
    display: flex;
    flex: 1;
    animation: benefitPie 0.5s linear 0s 1 alternate;
    justify-content: space-around;
    margin: auto;
    width: 100%;

    &--benefit {
      display: flex;
      flex-direction: column;
      align-items: center;

      .legend {
        display: flex;
        justify-content: center;
        width: 100%;
        gap: 80px;

        &__item {
          width: 20px;
          position: relative;
          white-space: nowrap;

          &::after {
            content: '';
            position: absolute;
            inset: 0;
            transform: translateX(-24px);
            width: 20px;
            height: 20px;
          }
        }

        .true {
          &::after {
            background: #0088fe;
          }
        }

        .false {
          &::after {
            background: #00c49f;
          }
        }
      }
    }

    &--ages {
      display: flex;
      gap: 36px;
      margin-top: 20px;

      .legend {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        &__item {
          width: 20px;
          position: relative;
          white-space: nowrap;

          &::after {
            content: '';
            position: absolute;
            inset: 0;
            transform: translateX(-24px);
            width: 20px;
            height: 20px;
          }
        }

        .zero {
          &::after {
            background: #0088fe;
          }
        }

        .one {
          &::after {
            background: #00c49f;
          }
        }

        .two {
          &::after {
            background: #ffbb28;
          }
        }

        .three {
          &::after {
            background: #ff8042;
          }
        }

        .four {
          &::after {
            background: #f28482;
          }
        }

        .five {
          &::after {
            background: #4a4e69;
          }
        }

        .six {
          &::after {
            background: #05668d;
          }
        }
      }
    }
  }
}

.queue-list__table {
  &--status {
    background-color: #5092aadc;
    color: white;
    border-radius: 10px;
  }

  p {
    text-align: center;
    margin: 0;
    padding: 0;
  }
}

@keyframes benefitPie {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@border-radius-base: 5px;