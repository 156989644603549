.warehouse-invoice {
  &__header {
    display: flex;
    padding: 24px;
    background: white;
    width: 100%;

    .ant-btn:nth-child(2) {
      margin-left: 24px;
    }

    .ant-btn:nth-child(3) {
      margin-left: auto;
    }
  }
}

@border-radius-base: 5px;