.to-top {
  height: 30px;
  width: 30px;
  line-height: 30px;
  border-radius: 14px;
  background-color: #5092aa;
  color: #fff;
  text-align: center;
}
.ant-back-top {
  right: 24px !important;
  bottom: 60px !important;
  opacity: 0.4;
}
.ant-layout-content {
  flex: none;
}
.logo {
  float: left;
}
.header {
  height: 70px;
}
main {
  background: #eef0f8;
  padding: 0 24px 24px 24px;
}
.ant-layout-content site-layout-background {
  min-height: 500px;
}

@media (max-width: 1500px) {
  .layout {
    min-width: 1500px;
  }
}

@border-radius-base: 5px;