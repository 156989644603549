.menu-meal-products {
  &__add {
    .title {
      font-weight: 600;
      margin: 0;
      margin-bottom: 8px;
    }

    .add-btn {
      margin: 0;
    }
  }

  &__current {
    margin-top: 16px;
    .title {
      font-weight: 600;
      margin: 0;
      margin-bottom: 8px;
    }

    .item {
      display: flex;
      justify-content: space-between;

      &__title {
        color: var(--primary-color);
        padding: 0;
        margin: 0 8px 0 0;
      }

      &__minus {
        border: 1px solid gray;
        display: flex;
        justify-content: center;
        background: whitesmoke;
        border-radius: 5px;
        width: 25px;
        opacity: 0.8;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

@border-radius-base: 5px;