.meal-types .ant-table-thead > tr > th {
  text-align: left;
}
.meal-types .ant-table-thead > tr > th:nth-child(1) {
  text-align: center;
}
.meal-types .ant-table-thead > tr > th:nth-child(4) {
  text-align: center;
}
.meal-types td.ant-table-cell {
  text-align: left;
}
.meal-types td.ant-table-cell:nth-child(4) {
  text-align: center;
}

.meal-types {
  &__header {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
  }
}

@border-radius-base: 5px;