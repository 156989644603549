.reference-district__wrapper {
  background-color: #fff;
  border-radius: 8px;
  padding: 25px;
  margin: 30px 0;
  position: relative;
}
.reference-book-district__header {
  border-bottom: 1px solid #ebedf3;
}
.reference-book-district__title {
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 15px;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@border-radius-base: 5px;