.warehouse {
  &__content {
    background: white;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 0 10px 0.1px #dfdfdf;
  }

  &__header {
    display: flex;
    gap: 2px;

    &--btn {
      text-align: center;
      flex: 1;
      padding: 8px 16px;
      font-size: 1rem;
      border-radius: 4px 4px 0 0;
      color: white;
      background: var(--primary-color);
      cursor: pointer;
      opacity: 0.9;
      transition: 0.3s;
      &:hover {
        opacity: 1;
        box-shadow: none;
      }
    }

    > .selected {
      opacity: 1;
      box-shadow: none;
      background: #40839b;
    }
  }

  &__filters {
    background: white;
    padding: 24px;
    box-shadow: 0 0 10px 0.1px #dfdfdf;

    &--row {
      display: flex;
      gap: 16px;

      .ant-form-item {
        flex: 1;
        margin: 0;
      }
    }
  }
}

@border-radius-base: 5px;