.timesheet {
  padding: 0 24px 24px 24px;

  &__popover-btn {
    cursor: pointer;
  }

  &__form {
    display: flex;
    align-items: center;
  }

  .ant-btn {
    margin-right: 8px;
  }

  &__select {
    display: flex;
    align-items: center;

    .title {
      margin: 0;
      margin-right: 8px;
    }

    .select {
      margin: 0;
    }
  }

  &__date-picker {
    margin-right: 8px !important;
  }

  &__download {
    margin-left: auto;
    .ant-btn {
      margin-right: 0 !important;
    }
  }
}

.employee-timesheet-table .ant-table-thead > tr > th {
  text-align: center;
}
.employee-timesheet-table .ant-table-thead > tr > th:nth-child(2) {
  text-align: left;
}
.employee-timesheet-table td.ant-table-cell {
  text-align: center;
}
.employee-timesheet-table td.ant-table-cell:nth-child(2) {
  text-align: left;
}

.pupil-timesheet-table .ant-table-thead > tr > th {
  text-align: center;
}
.pupil-timesheet-table .ant-table-thead > tr > th:nth-child(2) {
  text-align: left;
}
.pupil-timesheet-table td.ant-table-cell {
  text-align: center;
}
.pupil-timesheet-table td.ant-table-cell:nth-child(2) {
  text-align: left;
}

.timesheet-table .ant-table.ant-table-small {
  font-size: 12px;
}
.timesheet-table-row:hover td {
  background: #5092aa2d !important;
}
.timesheet-table .ant-table-cell {
  padding: 2px !important;
}
.timesheet-table .red-cell {
  background-color: rgba(255, 233, 233, 0.699);
  margin: 0px;
  border-radius: 5px;
}
.timesheet-table .green-cell {
  background-color: #005f6815;
  margin: 0px;
  border-radius: 5px;
}
.ellipsis-cell {
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  margin: 0px;
}

@border-radius-base: 5px;