.menu .ant-table-thead > tr > th {
  text-align: left;
}
.menu .ant-table-thead > tr > th:nth-child(1) {
  text-align: center;
}
.menu .ant-table-thead > tr > th:nth-child(3) {
  text-align: center;
}
.menu td.ant-table-cell {
  text-align: left;
}
.menu td.ant-table-cell:nth-child(3) {
  text-align: center;
}
.menu {
  .menu-row {
    cursor: pointer;
  }
  &__popup {
    position: absolute !important;
    padding: 16px;
    border-radius: 10px;
    left: var(--popup-x);
    top: var(--popup-y);
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid gray;
    cursor: pointer;

    &--title {
      font-size: 1.1rem;
      font-weight: 600;
      color: var(--primary-color);
    }

    &--meal-types {
      .title {
        font-weight: 600;
      }
    }

    p {
      padding: 0;
      margin: 0;
    }
  }

  .visible {
    visibility: visible;
  }

  .hidden {
    visibility: hidden;
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
  }
}

@border-radius-base: 5px;